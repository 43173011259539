import { ThemeProvider } from "@material-ui/core/styles";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import theme from "./components/theme";
import Home from "../src/views/home";
import Profile from "../src/views/profile";
import Event from "../src/views/event";
import HostAdd from "../src/views/host";
import NotFoundRoute from "../src/views/notFound";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/:id" component={Profile} /> 
          <Route exact path="/event/:id" component={Event} />
          <Route exact path="/stream/:id" component={Event} />
          <Route exact path="/host/add/KeNIhHQTHHw" component={HostAdd} />
          <Route exact path="/event/:id/:type" component={Event} />
          <Route exact path="/stream/:id/:type" component={Event} />
          <Route exact path="/links/event/:id/:type" component={Event} /> 
          <Route exact path="/links/events/:id/:type" component={Event} />
          <Route path="/not-found" component={NotFoundRoute} /> 
          <Route path="*" component={NotFoundRoute} /> 
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
