import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Box, Typography, Link } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
 
import YouTube from "react-youtube";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: 700,
    fontSize: "30px",
    lineHeight: "36px",
    paddingRight: 30,
    color: "#1C1C1E",
    fontFamily: "Helvetica",
    [theme.breakpoints.down("md")]: {
      paddingRight: 0,
    },
  },
  text: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "22px",
    color: "#4F4F4F",
    fontFamily: "Helvetica",
  },
  link: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1C1C1E",
    fontFamily: "Helvetica",
  },
  icon: {
    position: "relative",
    top: "7.5px",
    left: "-2px",
  },
  mediaImg: {
    [theme.breakpoints.down("xs")]: {
      width: "157px",
    },
  },
  mediaImgfirst: {
    marginRight: 16,
    [theme.breakpoints.down("xs")]: {
      width: "170px",
    },
  },
  mediapadding: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "50px",
    },
  },
  mediamobile: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  medialogo: {
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  imgSpace: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
}));

export default function CenteredGrid() {
  const classes = useStyles();
 
  return (
    <div className={classes.root}>
      

      <Box
        className="steev-main"
        style={{
          background: `url("/static/bryan-goff.png")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          position: "relative",
          textAlign: "center",
          width: "100%",
          height: "100vh",
          color: "#ffff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container>
          <Box>
            <Box mb={3} className="steev-hero">
              <img
                src="/static/logo.png"
                alt="logo"
                height="auto"
                width="170px"
              />
            </Box>
            <Typography
              variant="h4"
              color="inherit"
              style={{
                fontSize: 64,
                fontWeight: 600,
                marginBottom: "10px",
                fontFamily: "Helvetica-Bold",
              }}
            >
              Be a Rebel
            </Typography>
            <YouTube videoId="vPMXIBmu5fA" sx={{ iframe: { width: "100%" } }} />
            <Box mt={1.2} style={{ fontFamily: "Helvetica" }}>
              <Link
                underline="none"
                style={{ color: "#ffff" }}
                href="https://about.steevnetworks.com/"
              >
                Learn more about Steev
                <ChevronRightIcon
                  fontSize="small"
                  style={{
                    position: "relative",
                    top: "6px",
                    left: "-2px",
                  }}
                />
              </Link>
            </Box>
          </Box>
        </Container>
      </Box>
    </div>
  );
}
