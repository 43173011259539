import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../../assets/images/steev-logo.png";
import { Grid, Box, Avatar, Typography, Container, Modal } from "@material-ui/core/";
import axios from "axios";


import {API} from "../../config/index"
import Mysvg from "../../assets/share.svg";
import Mic from "../../assets/Mic.png";
import MicOptions from "../../assets/headset.svg";
import moment from "moment-timezone";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import PageNotFound from '../notFound/index'
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import FacebookIcon from "@material-ui/icons/Facebook";
import { blue } from "@material-ui/core/colors";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import RedditIcon from "@material-ui/icons/Reddit";
import {
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton,
} from "react-share";
import { useHistory } from 'react-router-dom';



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F2F2F2",
  },
  backgroundcolor: {
    backgroundColor: "white",
    borderRadius: "16px",
    boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.1)',
    padding: "16px",
    minHeight: 320,
    marginBottom: 50
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  Dialog: {
    "& .MuiPaper-root": {
      width: 320,
    },
  },
  social: {
    width: "100%",
  },
  username: {
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: 700,
    fontFamily: "Helvetica",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  usermail: {
    fontSize: "18px",
    lineHeight: "22px",
    fontWeight: 400,
    color: " rgba(28, 28, 30, 0.6)",
    fontFamily: "Helvetica",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  followingnumbr: {
    fontSize: "18px",
    lineHeight: "20px",
    fontWeight: 700,
    fontFamily: "Helvetica",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
    marginRight: "5px"
  },
  followingtext: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 700,
    fontFamily: "Helvetica",
    color: " rgba(28, 28, 30, 0.6)",
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  floatright: {
    float: "left",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      float: "none",
    },
  },

  texttitle: {
    color: "#8A8FA9",
    lineHeight: "20px",
    fontWeight: 700,
    fontFamily: "Helvetica",
    fontSize: "10px",
    marginTop: 20,
    textTransform: "uppercase",
  },
  backgroundcolortitle: {
    backgroundColor: "white",
    borderRadius: "16px",
    padding: "30px",
  },

  Striminglog: {
    backgroundColor: "white",
    borderRadius: "16px",
    boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.1)',
    padding: "12px",
  },
  clockiconcolor: {
    padding: "3px",
    color: "#8A8FA9",
    position: "relative",
    fontSize: "16px",
  },
  backgroundColorstreamdateTime: {
    backgroundColor: "#8A8FA933",
    borderRadius: "8px",
    marginBottom: "5px",
    width: "fit-content",
    color: "#8A8FA9",
    padding: "5px 5px 2px 5px",
    fontSize: "12px",
    display: "flex"
  },
  country: {
    backgroundColor: "#007AFF1A",
    color: " rgba(0, 122, 255, 0.8)",
    paddingLeft: "5px",
    borderRadius: "10px",
    width: "fit-content",
    marginBottom: "5px",
    padding: "2px 5px 2px 5px",
    fontSize: "14px",
    marginRight: "5px",
  },
  language: {
    backgroundColor: "#FDBD2F33",
    color: " rgba(253, 189, 47, 1)",
    width: "fit-content",
    borderRadius: "10px",
    marginBottom: "5px",
    padding: "2px 5px 2px 5px",
    marginRight: "5px",
    fontSize: "14px",
  },
  startup: {
    backgroundColor: "#28CD411A",
    color: "rgba(40, 205, 65, 1)",
    width: "fit-content",
    borderRadius: "10px",
    marginBottom: "5px",
    padding: "2px 5px 2px 5px",
    fontSize: "14px",
  },
  quation: {
    color: "black",
    fontSize: "16px",
    marginBottom: "5px",
    fontFamily: 'Helvetica',
    lineHeight: '20px',
    display: "flex",
    fontWeight: "700"
  },
  streamers: {

    fontSize: "14px",
    fontWeight: 700,
    display: "flex"
  },
  mainsteamer: {
    fontSize: "14px",
    color: "black"
  },
}));

export default function FullWidthGrid({ match }) {

  const [show, setShow] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState();
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [state, setstate] = React.useState({
    data: null,
    events: []
  });

  const handleClickOpen = (val) => {
    setOpen(val);

  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  function delay() {
    setTimeout(() => {
      setLoading(false)
    }, 1000);
  }


  React.useEffect(async () => {
   
    // Fetch the user profile data using the ID from the URL parameter
    await axios
      .get(`${API}/api/v1/user/profile/${match.params.id}`)
      .then(async (res) => {
        console.log(res)
        // Check if the response data contains the profile data
           console.log('Profile Data: ' + match.params.id)     
            if (res.data.data) {
              // Data is present, proceed with setting the state and loading the page
              console.log('Get Profile Data: ' + res)
              setstate({ ...state, data: res.data.data });
              return await axios.get(
                `${API}/api/v1/users/events/${res.data.data.profile._id}`
              ).then((response) => {
                if (response.data.code === 200) {
                  setstate({ ...state, data: res.data.data, events: response.data.data });
                }else if(response.data.code === 400){
                  // history.push('/not-found')
                  console.log(response.data.code)
                }else
                {
                  console.log(response.data.code)
                }
                delay();
              }).catch((err) => {
                delay()
                console.log('Error in Host', err);
              });
            } else {
              console.log('Missing Record')
        
            }
      }).catch((error)=> {
        delay()
        console.log('Profile not found', error);
      });
  }, []);


  function stripHtml(html) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }

  const mytimeZone = (item) => {
    var tzone = moment.tz.guess();
    let dateSplit = item.scheduleDate.split("T");
    const ac = moment(dateSplit[0] + item.scheduleTime).format(
      "YYYY-MM-DD HH:mm"
    );
    const tzConv = moment.tz(ac, item.timeZone);
    const convertedDt = tzConv
      .tz(
        typeof tzone === "Number"
          ? Intl.DateTimeFormat().resolvedOptions().timeZone
          : tzone
      )
      .format("MMM D YYYY  hh:mm A zz"); // mytimeZone  is my zone
    return convertedDt;
  };



  let hostName = "steev";
  let metaTitle = "Steev";
  let metaUrl = "https://www.steevnetworks.com" + window.location.pathname + window.location.search;
  let metaDesc = "People come to Steev to listen and participate conversations with amazing people";
  let metaKeywords = "live conversations,listen music, steev";
  let metaImage = "https://www.steevnetworks.com/cropped-Steev-Icon-Black-180x180.png";
  let avtarName = "";
  if (loading == false && state.data) {
    if (state.data.profile) {
      hostName = state.data.profile.name;
      const spt = hostName.split(" ");
      spt.forEach((item) => {
        if (item !== "" && item !== null && item !== undefined && avtarName.length < 2) {
          if (avtarName == "") {
            avtarName = item.charAt(0);
          }
          else {
            avtarName += item.charAt(0);
          }
        }
      })
    }
    let bio = ""
    if (state.data.profile.bio !== null
      && state.data.profile.bio !== undefined
      && state.data.profile.bio != "") {
      bio = stripHtml(state.data.profile.bio)
    }
  }

  if(loading===false && state.data === null){
    return <PageNotFound />
  }
  return (
    <div className={classes.root}>
      {loading ? (
        <Box
          height="100vh"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img src={Logo} alt="logo" height="auto" width="170.81px" />
        </Box>
      ) : (
        <>

          <Grid container justifyContent="center">
            <Grid item md={9} style={{ padding: 42 }}>
              <Container fixed>
                <Box py={5} textAlign="center">
                  <img src={Logo} alt="steev" height="auto" width="170.81px" />
                </Box>
                <Box className={classes.backgroundcolor}>
                  <Grid container spacing={3}>
                    <Grid item lg={6} xs={8}>
                      <Grid container spacing={8}>
                        <Grid item lg={9} xs={12}>
                          <Box display="flex" alignItems="center">
                            {state.data.profile.profilePic !== undefined ? (

                              <Avatar
                                alt={state.data.profile.name}
                                src={state.data.profile.profilePic}
                                className={classes.large}></Avatar>


                            ) : (
                              <Avatar
                                alt={state.data.profile.name}
                                style={{ background: "gray" }}
                                src={state.data.profile.profilePic}
                                className={classes.large}
                              > {avtarName} </Avatar>
                            )}

                            <Box ml={2}>
                              <Typography className={classes.username}>
                                {state.data.profile.name}
                              </Typography>
                              <Typography className={classes.usermail}>
                                @{state.data.profile.username}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={6} xs={5}>
                      <Grid container spacing={2} style={{ marginTop: 0 }}>
                        <Grid item xs={4} >
                          <Box className={classes.floatright} pt={1}>
                            <Typography className={classes.followingnumbr}>
                              {state.data.followingCount}
                            </Typography>
                            
                             <Typography className={classes.usermail}>
                               {state.data.followingCount > 1 ? 'Followings' : 'Following'}
                            </Typography> 
                           
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box pt={1} className={classes.floatright}>
                            <Typography className={classes.followingnumbr}>
                              {state.data.followersCount}
                            </Typography>
                            <Typography className={classes.usermail}>
                              {state.data.followersCount > 1 ? 'Followers':   'Follower'}
                            </Typography> 
                            
                         
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box pt={1} className={classes.floatright}>
                            <Typography className={classes.followingnumbr}>
                              {state.events.length}
                            </Typography>

                            <Typography className={classes.usermail}>
                              {state.events.length > 1 ? 'Streams':'Stream' }
                            </Typography>
                            
                           

                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Typography className={classes.texttitle}>about</Typography>
                  <Typography>
                    <Box
                      py={2}
                      fontFamily="Helvetica"
                      lineHeight="22px"
                      fontSize="16px"
                      color="#1C1C1E"
                      dangerouslySetInnerHTML={{
                        __html: state.data.profile.bio !== undefined ? state.data.profile.bio : '...',
                      }}
                    />
                  </Typography>
                </Box>

                {open &&
                  <SimpleDialog
                    selectedValue={selectedValue}
                    open={open}
                    onClose={handleClose}
                  />
                }

                {state.events.length > 0 ? <Typography className={classes.usermail}>
                  <span>Streams</span>
                </Typography> : <Typography className={classes.usermail}>
                  <span>Stream</span>
                </Typography>}
                <Grid container justifyContent="space-between">
                  {state.events.length > 0 && state.events.map((item) => {
                    const urlRedirect = `https://steevnetworks.com/event/${item._id}/online`
                    return (
                      <Grid item xs={4}>
                        <Box my={3} className={classes.Striminglog} sx={{ m: 0.5 }}>
                          <Grid container justifyContent="space-between" >
                            <Typography className={classes.backgroundColorstreamdateTime}>
                              <AccessTimeIcon className={classes.clockiconcolor} />
                              {mytimeZone(item)}</Typography>
                            <Typography><img src={Mysvg} onClick={() => handleClickOpen(item)}></img></Typography>
                          </Grid>
                          <Grid container style={{ marginTop: 10 }}>
                            <Typography className={classes.country}>{item.tags[1]}</Typography>
                            <Typography className={classes.language}>{item.tags[0]}</Typography>
                            <Typography className={classes.startup}>{item.tags[2]}</Typography>
                          </Grid>
                          <Typography style={{ marginTop: 10 }}
                            className={classes.quation}><a href={urlRedirect}
                              style={{ color: "black", textDecoration: "none" }}>{item.title}</a></Typography>
                          <Grid justifyContent="space-around" style={{ marginTop: 10 }}>
                            <Typography className={classes.streamers}><img src={Mic} ></img><span style={{ color: "black" }}>{item.userId.name}</span>  </Typography>
                          </Grid>
                          <Typography style={{ marginTop: 10 }} className={classes.streamers}><img src={MicOptions} style={{ color: "black", width: "13px", marginRight: "5px", marginLeft: 3 }} />{item.viewers.length}</Typography>
                        </Box>
                      </Grid>
                    )
                  })}
                  {state.events.length == 0 &&
                    <Grid container justifyContent="center" >
                      <Typography style={{ marginTop: 10, fontSize: "30px", fontWeight: "500" }}  >
                        {'No Streams'}</Typography>
                    </Grid>
                  }
                </Grid>
              </Container>
            </Grid>
          </Grid>


        </>
      )}
      <Box py={10} />
    </div>
  );
}

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;
  console.log('open', open)
  const urlRedirect = `https://steevnetworks.com/event/${open._id}/online`;
  console.log('urlRedirect', urlRedirect)
  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog
      className={classes.Dialog}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Share</DialogTitle>
      <List style={{ display: 'inline', width: 300 }}>
        <FacebookShareButton
          className={classes.social}
          url={urlRedirect}
        >
          <ListItem button onClick={() => handleListItemClick("sad")}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <FacebookIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={"Facebook"} />
          </ListItem>
        </FacebookShareButton>
        <TwitterShareButton
          className={classes.social}
          url={urlRedirect}
        >
          <ListItem button onClick={() => handleListItemClick("sad")}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <TwitterIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={"Twitter"} />
          </ListItem>
        </TwitterShareButton>
        <LinkedinShareButton
          className={classes.social}
          url={urlRedirect}
        >
          <ListItem button onClick={() => handleListItemClick("sad")}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <LinkedInIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={"Linkendin"} />
          </ListItem>
        </LinkedinShareButton>
        <RedditShareButton
          className={classes.social}
          url={urlRedirect}
        >
          <ListItem button onClick={() => handleListItemClick("sad")}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <RedditIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={"Reddit"} />
          </ListItem>
        </RedditShareButton>
      </List>
    </Dialog>
  );
}