import 'date-fns';
import React from "react";
import { makeStyles,withStyles,alpha } from "@material-ui/core/styles";
import Logo from "../../assets/images/steev-logo.png";
import { Grid, Box, Avatar, Typography, Container,InputBase  } from "@material-ui/core/";
import axios from "axios";
import {API} from "../../config/index"
 
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import ScheduleIcon from '@material-ui/icons/Schedule';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';

 

const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
      '& .MuiFormLabel-root.Mui-focused':{
        color: '#1C1C1E !important',
        
      }
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      border:0,
      fontSize: 16,
      width: '100%',
      borderRadius:16,
      color: '#1C1C1E',
      padding: '15px 12px',
      fontWeight: 500,
      background:"#8A8FA90D",
      fontFamily: "Helvetica",
      transform:'none',
      '& .MuiInput-underline::before':{
        border:'none !important',
        borderBottom:'none !important',
        transform:'none',
      },
      '& :hover':{
        border:'none !important',
        transform:'none',
      },
      '& .MuiInput-underline::after':{
        transform:'none',
        border:'none !important',
      },
    },
  }))(InputBase);
  
 

const useStyles = makeStyles((theme) => ({
  root: {
    border:'none !important',
    backgroundColor: "#F2F2F2",
    '& .MuiInput-underline::before':{
      border:'none !important',
      borderBottom:'none !important',
    },
    '& :hover':{
      border:'none !important',
    },
    '& .MuiInput-underline::after':{
      transform:'none',
      border:'none !important',
      borderBottom:'none !important',
    },
  },
  mt:{  
    marginTop:'30px !important',
    '& .MuiInput-underline::before':{
      border:0
    },
    '& :hover':{
      border:0
    },
  '& .MuiInput-underline':{
    border:0
  }
  },
  backgroundcolor: {
    backgroundColor: "white",
    borderRadius: "8px",
    padding: "30px",
    marginBottom: "50px",
    minHeight:"850px",
    boxShadow: "4px 2px 16px rgba(0, 0, 0, 0.1)"
 
  },
  
  submitButtton:{
    width:'100%',
    height:45,
    marginTop:30,
    color: "#fff",
    padding:0,
    borderRadius: 14,
    fontFamily: "Helvetica",
    fontSize: "16px",
    background:"#007AFF",
    '& .MuiButton-label':{
      height:45,
      borderRadius: 14,
      fontFamily: "Helvetica",
      fontSize: "18px",
      fontWeight:600,
      textTransform:'capitalize'
    },
    '&:hover':{
      background:"#007AFF",
      color: "#fff",
      padding:0
    }
  },
  user:{
    fontFamily: "Helvetica",
    fontSize: "12px",
    color: "#1C1C1E",
    fontWeight: 700,
    marginTop:10,
    marginLeft:10
  },
  texttitle: {
    color: "#1C1C1E",
    lineHeight: "20px",
    fontWeight: 700,
    marginTop:30,
    fontFamily: "Helvetica",
    fontSize: "26px",
    textAlign:'center',
  
  },
  label:{
    lineHeight: "20px",
    fontWeight: 700,
    color:"#8A8FA9",
    width:'100%',
    fontStyle:'normal',
    letterSpacing:'2px',
    fontFamily: "Helvetica",
    fontSize: "18px",
  },
  backgroundcolortitle: {
    backgroundColor: "white",
    borderRadius: "16px",
    padding: "30px",
  },
  margin:{
      width:'100%',
      marginTop:30,
      marginBottom:0,
     
  },
  icon:{
    color:"#fff",
    fontWeight: 600,
    fontSize: 20,
    marginRight:10
  },
  chip:{
    marginRight:8,
    marginLeft:0,
    marginTop:10,
    '&:hover':{
      background:   '#1976d2',
    }

  },
  err:{
    color:'red',
    marginTop:10,
    marginBottom:10
  },
  textField:{
    marginTop:30,
    
    fontSize: 16,
    width: '100%',
    borderRadius:16,
    color: '#1C1C1E',
    padding: '10px 12px',
    fontWeight: 500,
    background:"#8A8FA90D",
    '& .MuiInput-underline::after':{
      transform:'none',
      border:0
    },
    '& .MuiInput-underline::before':{
      transform:'none',
      border:0
    },
    '& :hover':{
      border:0
    },
    '& MuiInputBase-formControl::before':{
      border:'none'
    },
    '& MuiInputBase-formControl::after':{
      border:'none'
    },
    '& MuiInputBase-formControl:hover':{
      border:'none'
    }
  }
}));

export default function FullWidthGrid({ match }) {
  const classes = useStyles();
  const [state, setstate] = React.useState({
    loading: false,
    data: null,
    tagsArr:false,
    savedTags:[],
    errors:[]
  });
  let typeArr = [
    {
      label:"MP3",
      name:"mp3"
    },
    {
      label:"MP4",
      name:"mp4"
    },
    {
      label:"Youtube",
      name:"youtube"
    },
  ]
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [linkType, setLinkType] = React.useState("mp3");
  const handleLinkType=(val)=>{
    
    setLinkType(val)
  }
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleDelete = (date) => {
    setSelectedDate(date);
  };
  const handleTags =(val)=>{
        if(state.savedTags.indexOf(val) !==-1){
          let tags=  state.savedTags.filter((item)=>item!==val);
          setstate({ ...state, savedTags: tags, loading: false })
        }else{
          let tagsv =  state.savedTags;
          if(tagsv.length<3){
            tagsv.push(val)
            setstate({ ...state, savedTags: tagsv, loading: false })
          }
          else{
            alert('You can select maximum 3 tags only')
          }
         
         
        }
  }
  const getInterest = async () =>{

    await axios
      .get(`${API}/api/v1/get-interest`)
      .then((res) => {
        console.log('Interess',res)
        setstate({ ...state, tagsArr: res.data.data[0], loading: false })
      });

  }
  const handleSave = async () =>{
      const link= document.getElementById("link").value;
   
      const expireDate= document.getElementById("expireDate").value;
      const description= document.getElementById("desc").value;
      const title= document.getElementById("title").value;
      const sponserLink= document.getElementById("sponserName").value;
      const sponserImg= document.getElementById("sponserLogo").value;
      let arr =[];
      if(title==""){
        arr.push({
            name:"title",
            value:"Title is required"
        })
      }
      if(description==""){
        arr.push({
            name:"desc",
            value:"Description is required"
        })
      }
      if(expireDate==""){
          arr.push({
              name:"expireDate",
              value:"Expire Date is required"
          })
      }
      if(linkType==""){
          arr.push({
              name:"linkType",
              value:"Link Type is required"
          })
      }
      if(link==""){
          arr.push({
              name:"link",
              value:"Link is required"
          })
      }

      if(state.savedTags.length==0){

            arr.push({
                name:"tags",
                value:"Tags is required"
            })
      }
      console.log(linkType)

      if(arr.length==0)
      {
            await axios
                  .post(`${API}/api/v1/save-host`,{
                    title,
                    linkType,
                    description,
                    expireDate,
                    link,
                    sponserLink,
                    sponserImg,
                    tags:state.savedTags,

                  })
                  .then((res) => {
                      console.log('save-host',res)
                      alert('Success ! Host has been added successfully ')
                      window.location.reload();
                    // setstate({ ...state, tagsArr: [], loading: false })
                  });
      }
      else{
        setstate({ ...state, errors: arr, loading: false })
      }
      

  }
  React.useEffect(() => {
    getInterest();
  }, []);

  const displayError = (type)=>{
    let filter = state.errors.filter((item)=> type==item.name);
    console.log('type',filter)
    console.log('type2',type)
    if(filter.length>0){
      return (
        <span className={classes.err}>{filter[0].value}</span>
      )
    }
    else{
      return (
        <span  ></span>
      )
    }
   
  }

 

  return (
    <div className={classes.root}>
       
      {state.loading ? (
        <Box
          height="100vh"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img src={Logo} alt="logo" height="auto" width="170.81px" />
        </Box>
      ) : (
        <>
          
          <Grid container justifyContent="center">
            <Grid item md={5} >
              <Container fixed>
                <Box py={5} textAlign="center">
                  <img src={Logo} alt="logo" height="auto" width="170.81px" />
                </Box>
                <Box className={classes.backgroundcolor}>
                   <Typography className={classes.texttitle} component={"h5"} variant="h5">
                      {'Host your show'}
                    </Typography>
                    <FormControl className={classes.margin}>
                        <InputLabel  className={classes.label} shrink htmlFor="bootstrap-input">
                           Title 
                        </InputLabel>
                      <BootstrapInput defaultValue="" id="title" />
                      {displayError('title')}
                    </FormControl>
                     <FormControl className={classes.margin}>
                        <InputLabel  className={classes.label} shrink htmlFor="bootstrap-input">
                           Description
                        </InputLabel>
                      <BootstrapInput defaultValue="" id="desc" />
                      {displayError('desc')}
                    </FormControl>
                    <FormControl className={classes.margin}>
                        <InputLabel  className={classes.label} shrink htmlFor="bootstrap-input">
                           Expiration Date
                        </InputLabel>
                      
                        <TextField
                            id="expireDate"
                            type="date"
                            defaultValue={new Date()}
                            style={{width:'94%'}}
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                           {displayError('expireDate')}
                    </FormControl>
                    <FormControl className={classes.margin}>
                        <InputLabel  className={classes.label} shrink htmlFor="bootstrap-input">
                          Host
                        </InputLabel>
                        <div style={{display:'flex',marginTop:30}}>
                           <Avatar alt="Remy Sharp" src="/steev-logo.png" />
                            <Typography variant="button" className={classes.user} display="block" gutterBottom>
                              Steev
                            </Typography>
                          </div>
                       
                    </FormControl>
                  
                    {state.tagsArr &&
                    <div style={{marginTop:20}}>
                       <InputLabel  className={classes.label} shrink htmlFor="bootstrap-input">
                          Languages
                        </InputLabel>
                            {state.tagsArr.languages.map((item)=>{
                              return (
                                <>
                                {state.savedTags.indexOf(item.name) !==-1 ? ( 
                                 <Chip
                                  label={item.name}
                                  clickable
                                  className={classes.chip}
                                  color="secondary"
                                  onClick={e=>handleTags(item.name)}
                                 
                                  onDelete={e=>handleTags(item.name)}
                                  deleteIcon={<HighlightOffIcon />}
                                   
                                  />
                                ):(
                                  <Chip
                                  label={item.name}
                                  clickable
                                  className={classes.chip}
                                  color="secondary"
                                  onClick={e=>handleTags(item.name)}
                                  deleteIcon={<></>}
                                  onDelete={e=>handleTags(item.name)}
                                  variant="outlined"
                                  />
                                )}
                                </>
                               
                              )
                            })}
                        <InputLabel  className={classes.label} style={{marginTop:10}} shrink htmlFor="bootstrap-input">
                          Regions
                        </InputLabel>
                             {state.tagsArr.regions.map((item)=>{
                              return (
                                <>
                                {state.savedTags.indexOf(item.name) !==-1 ? ( 
                                 <Chip
                                  label={item.name}
                                  clickable
                                  className={classes.chip}
                                  color="secondary"
                                  onClick={e=>handleTags(item.name)}
                                 
                                  onDelete={e=>handleTags(item.name)}
                                  deleteIcon={<HighlightOffIcon />}
                                   
                                  />
                                ):(
                                  <Chip
                                  label={item.name}
                                  clickable
                                  className={classes.chip}
                                  color="secondary"
                                  onClick={e=>handleTags(item.name)}
                                  deleteIcon={<></>}
                                  onDelete={e=>handleTags(item.name)}
                                  variant="outlined"
                                  />
                                )}
                                </>
                              )
                            })}

                        <InputLabel  className={classes.label} style={{marginTop:10}} shrink htmlFor="bootstrap-input">
                          Topic
                        </InputLabel>
                              {state.tagsArr.topics.map((item)=>{
                              return (
                                <>
                                {state.savedTags.indexOf(item.name) !==-1 ? ( 
                                 <Chip
                                  label={item.name}
                                  clickable
                                  className={classes.chip}
                                  color="secondary"
                                  onClick={e=>handleTags(item.name)}
                                  deleteIcon={<></>}
                                  onDelete={e=>handleTags(item.name)}
                                  deleteIcon={<HighlightOffIcon />}
                                   
                                  />
                                ):(
                                  <Chip
                                  label={item.name}
                                  clickable
                                  className={classes.chip}
                                  color="secondary"
                                  onClick={e=>handleTags(item.name)}
                                  deleteIcon={<></>}
                                  onDelete={e=>handleTags(item.name)}
                                  variant="outlined"
                                  />
                                )}
                                </>
                              )
                            })}
                    </div>
                    }
                    {displayError('tags')}

                    <FormControl className={classes.margin}>
                        <InputLabel  className={classes.label} shrink htmlFor="bootstrap-input">
                          Link Type  
                        </InputLabel>
                        <Select defaultValue={linkType} value={linkType} onChange={e=>handleLinkType(e.target.value)} id="linkType"   className={[classes.textField,classes.mt]} 
                        id="linkType">
                          {typeArr.map((item)=>{
                            return (
                              <MenuItem value={item.name}>{item.label}</MenuItem>
                            ) 
                          })}
                       </Select>
                       {displayError('linkType')}
                    </FormControl>
                    <FormControl className={classes.margin}>
                        <InputLabel  className={classes.label} shrink htmlFor="bootstrap-input">
                           Link
                        </InputLabel>
                      <BootstrapInput defaultValue="" id="link" />
                      {displayError('link')}
                    </FormControl>
                    <FormControl className={classes.margin}>
                        <InputLabel  className={classes.label} shrink htmlFor="bootstrap-input">
                          Brought By (Website)
                        </InputLabel>
                      <BootstrapInput defaultValue="" id="sponserName" />
                   
                    </FormControl>
                    <FormControl className={classes.margin}>
                        <InputLabel  className={classes.label} shrink htmlFor="bootstrap-input">
                        Brought By (Logo)
                        </InputLabel>
                      <BootstrapInput defaultValue="" id="sponserLogo" />
                      
                    </FormControl>
                        <Button onClick={e=>handleSave()} className={classes.submitButtton} variant="contained"
                         color="secondary" disableElevation> <ScheduleIcon style={{ fontSize: 22 }} 
                         className={classes.icon} /> Schedule </Button>
                </Box>

              
              </Container>
            </Grid>
          </Grid>
        </>
      )}
    
      <Box py={10} />
      
    </div>
  );
}
