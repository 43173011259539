import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../../assets/images/steev-logo.png";
import Share from "../../assets/images/share.png";
import Copy from "../../assets/images/copy.png";
import Calender from "../../assets/images/calender.png";
import Google from "../../assets/images/Google.png";
import AppStore from "../../assets/images/App-store.png";
import Chip from "@material-ui/core/Chip";
import { PlayerIcon } from "react-player-controls";
import { FormattedTime } from "react-player-controls";
import PageNotFound from '../notFound/index'

// import ProgressBar from './slider'
import ReactPlayer from "react-player";
// import Slider from '@material-ui/core/Slider';
import Slider from "rc-slider";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";

import { ICalendar } from "datebook";
import moment from "moment-timezone";
import {
  Grid,
  Box,
  Avatar,
  Typography,
  Container,
  IconButton,
  Link,
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import axios from "axios";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import FacebookIcon from "@material-ui/icons/Facebook";
import { blue } from "@material-ui/core/colors";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import RedditIcon from "@material-ui/icons/Reddit";
import {
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton,
} from "react-share";
import { CLOUD_FRONT_DEV_URL, API } from '../../config'
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F2F2F2",
  },
  backgroundcolor: {
    backgroundColor: "white",
    borderRadius: "16px",
    padding: "20px",
  },
  large: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    margin: 'auto',
    "& img": {
      position: "relative",
      top: 0,
      left: 0,
    },
  },
  clockbg: {
    backgroundColor: "rgba(138, 143, 169, 0.2)",
    borderRadius: "8px",
    padding: "3px 10px 3px 10px",
    display: "inline-block",
    height: "24px",
  },
  clockiconcolor: {
    padding: "3px",
    color: "#8A8FA9",
    position: "relative",
    fontSize: "16px",
  },
  datetext: {
    fontSize: "12px",
    position: "relative",
    top: "-6px",
    color: "#8A8FA9",
  },
  borderbg: {
    backgroundColor: "#E4EFF7",
    borderRadius: "18px",
    height: 48,
    width: 48,
  },
  mediatext: {
    [theme.breakpoints.only("md")]: {
      fontSize: "12px",
    },
  },
  medialogo: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  mediaImgfirst: {
    marginRight: 16,
    [theme.breakpoints.down("xs")]: {
      width: "170px",
    },
  },
  mediaImg: {
    [theme.breakpoints.down("xs")]: {
      width: "157px",
    },
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  Dialog: {
    "& .MuiPaper-root": {
      width: 320,
    },
  },
  social: {
    width: "100%",
  },
  cursor: {
    cursor: "pointer",
    justifyContent: "center",
    display: "grid",
  },
}));
const emails = ["username@gmail.com", "user02@gmail.com"];

const SpeakerOffIcon = () => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 16 16"
    height="26"
    width="26"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M8 2.81v10.38c0 .67-.81 1-1.28.53L3 10H1c-.55 0-1-.45-1-1V7c0-.55.45-1 1-1h2l3.72-3.72C7.19 1.81 8 2.14 8 2.81zm7.53 3.22l-1.06-1.06-1.97 1.97-1.97-1.97-1.06 1.06L11.44 8 9.47 9.97l1.06 1.06 1.97-1.97 1.97 1.97 1.06-1.06L13.56 8l1.97-1.97z"
    ></path>
  </svg>
);

const SpeakerOnIcon = () => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 16 16"
    height="26"
    width="26"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M12 8.02c0 1.09-.45 2.09-1.17 2.83l-.67-.67c.55-.56.89-1.31.89-2.16 0-.85-.34-1.61-.89-2.16l.67-.67A3.99 3.99 0 0 1 12 8.02zM7.72 2.28L4 6H2c-.55 0-1 .45-1 1v2c0 .55.45 1 1 1h2l3.72 3.72c.47.47 1.28.14 1.28-.53V2.81c0-.67-.81-1-1.28-.53zm5.94.08l-.67.67a6.996 6.996 0 0 1 2.06 4.98c0 1.94-.78 3.7-2.06 4.98l.67.67A7.973 7.973 0 0 0 16 8c0-2.22-.89-4.22-2.34-5.66v.02zm-1.41 1.41l-.69.67a5.05 5.05 0 0 1 1.48 3.58c0 1.39-.56 2.66-1.48 3.56l.69.67A5.971 5.971 0 0 0 14 8.02c0-1.65-.67-3.16-1.75-4.25z"
    ></path>
  </svg>
);

const NextIcon = () => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 24 24"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 18l8.5-6L6 6v12zM16 6v12h2V6h-2z"></path>
  </svg>
);

const PreviousIcon = () => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 24 24"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 6h2v12H6zm3.5 6l8.5 6V6z"></path>
  </svg>
);

const PlayIcon = () => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 512 512"
    className="react-jinke-music-player-play-icon"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm115.7 272l-176 101c-15.8 8.8-35.7-2.5-35.7-21V152c0-18.4 19.8-29.8 35.7-21l176 107c16.4 9.2 16.4 32.9 0 42z"></path>
  </svg>
);

const PauseIcon = () => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 512 512"
    className="react-jinke-music-player-pause-icon"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm-16 328c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16v160zm112 0c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16v160z"></path>
  </svg>
);

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog
      className={classes.Dialog}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Share</DialogTitle>
      <List>
        <FacebookShareButton
          className={classes.social}
          url={window.location.href}
        >
          <ListItem button onClick={() => handleListItemClick("sad")}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <FacebookIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={"Facebook"} />
          </ListItem>
        </FacebookShareButton>
        <TwitterShareButton
          className={classes.social}
          url={window.location.href}
        >
          <ListItem button onClick={() => handleListItemClick("sad")}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <TwitterIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={"Twitter"} />
          </ListItem>
        </TwitterShareButton>
        <LinkedinShareButton
          className={classes.social}
          url={window.location.href}
        >
          <ListItem button onClick={() => handleListItemClick("sad")}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <LinkedInIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={"Linkendin"} />
          </ListItem>
        </LinkedinShareButton>
        <RedditShareButton
          className={classes.social}
          url={window.location.href}
        >
          <ListItem button onClick={() => handleListItemClick("sad")}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <RedditIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={"Reddit"} />
          </ListItem>
        </RedditShareButton>
      </List>
    </Dialog>
  );
}

export default function FullWidthGrid({ match, history }) {
  const classes = useStyles();

  const [state, setstate] = React.useState({
    loading: true,
    hosts: [],
    data: null,
    shows: [],
    date: null,
  });
  const [url, seturl] = React.useState(null);

  const [loading, setLoading] = React.useState(true);

  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);
  const [currentTime, setTime] = React.useState(0);
  const playerRef = useRef(null);
  const [isPlaying, setPlaying] = useState(false);
  const [viewers, setViewers] = useState(0);

  const [isMuted, setMuted] = useState(false);
  const [index, setIndex] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [progessObj, setProgressObj] = useState({
    playedSeconds: 0,
    loadedSeconds: 0,
  });
  const [sv, seek] = React.useState(false);

  const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
  }


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const generateIcs = () => {
    const config = {
      title: state.data.title,
      description: state.data.description,
      start: new Date(state.data.scheduleDate),
    };

    const icalendar = new ICalendar(config);
    icalendar.download("event.ics");
  };

  const mytimeZone = () => {
    var tzone = moment.tz.guess();
    let dateSplit = state.data.scheduleDate.split("T");
    const ac = moment(dateSplit[0] + state.data.scheduleTime).format(
      "YYYY-MM-DD HH:mm"
    );
    const tzConv = moment.tz(ac, state.data.timeZone);
    const convertedDt = tzConv
      .tz(
        typeof tzone === "Number"
          ? Intl.DateTimeFormat().resolvedOptions().timeZone
          : tzone
      )
      .format("MMM DD, YYYY hh:mmA zz"); // mytimeZone  is my zone
    return convertedDt;
  };

  const updateViewers = () => {
    axios
      .post(`${API}/viewers/update`, {
        hostId: match.params.id
      })
      .then(async (response) => {
        if (response && response.status === 200) {
          if (response.data.code == 200 && response.data.data) {
            setViewers(response.data.data)
          }
        }
      }).catch((err) => {
        console.log('An error in occured in updating viewers', err)
      })
  }

  React.useEffect(() => {
    let two = `${API}/random-stream`;
    const hosts = [];
    let eventData = false;

    if (match.params.id) {

      axios
        .get(`${API}/host/${match.params.id}`)
        .then(async (response) => {
          if (response && response.data) {
            eventData = response.data.data;
            //Add a State 
            if (response.data.data.tags) {
              return await axios.post(two, { tags: response.data.data.tags });
            }
            else {
              return false;
            }



          } else {
            return false;
          }
        }).then((response) => {


          if (response && response.data) {



            let filter = [];

            if (response.data.data) {
              filter = response.data.data;
              try {
                if (filter.length > 3) {
                  const max = filter.length - (filter.length > 3 ? 3 : 0);
                  const random = getRandomInt(max);
                  filter = filter.slice(random, random + 3);

                }


              } catch (err) {
                filter = filter.slice(0, (filter.length > 3 ? 3 : filter.length));
              }

              if (eventData.approvedCoHost) {
                eventData.approvedCoHost.forEach((coHost) => {
                  hosts.push({
                    type: "CO-HOST",
                    name: coHost.name,
                    profilePic: coHost.profilePic,
                    username: coHost.username,
                  });
                });
              }
              setstate((oldState) => ({
                ...oldState,
                data: eventData,
                shows: filter,
                hosts: hosts,
                loading: false,
                time: '',
              }));
              setViewers(eventData.viewers.length)
              if (eventData && eventData.category == "offline") {

                if (eventData.link) {
                  seturl(eventData.link)
                }
                if (eventData.records && eventData.records.length > 0) {
                  seturl(CLOUD_FRONT_DEV_URL + eventData.records[0].fileList[0].fileName)
                }
              }

              if (eventData && eventData.category == "online") {
                if (eventData.records.length > 0) {
                  seturl(CLOUD_FRONT_DEV_URL + eventData.records[0].fileList[0].fileName)
                }
              }
              if (eventData.userId) {
                hosts.push({
                  type: "HOST",
                  name: eventData.userId.name,
                  profilePic: eventData.userId.profilePic,
                  username: eventData.userId.username,
                });
              }


            }

          } else {
            console.log('page not found')
          }
          delay()
        }).catch((errors) => {
          delay()
          // history.push('/not-found');
          console.log('Errors ', errors)
          // react on errors.
        });

    } else {
      delay()
    }


  }, []);

  let hostName = "";

  if (state.loading == false && state.data) {

    if (state.hosts.length > 0) {
      hostName = state.hosts[0].name;
    }
  }

  const displayTags = (tags, wd) => {
    return (
      <Grid container spacing={3}>
        {tags.map((item, index) => {
          let bgColor = "rgba(0, 122, 255, 0.1)";
          let fColor = "rgba(0, 122, 255, 0.8)";

          if (index == 1) {
            bgColor = "rgba(253, 189, 47, 0.2)";
          }

          if (index == 2) {
            bgColor = "rgba(40, 205, 65, 0.1)";
          }
          return (
            <Chip
              className={"tag-d tag-d-" + index}
              style={{
                marginLeft: 5,
                minWidth: wd,
                background: bgColor,
              }}
              label={item}
            />
          );
        })}
      </Grid>
    );
  };

  const displayShows = (data) => {
    return (
      <a href={data.firelink} style={{ textDecoration: 'none' }}>
        <Grid
          style={{
            width: 270,
            padding: 25,
            background: "#fff",
            borderRadius: 8,
            marginBottom: 20,
          }}
        >
          <div style={{ marginTop: 10 }}>{displayTags(data.tags, 80)}</div>


          <Typography>
            <Box
              py={1}
              mt={2}
              fontWeight="700"
              fontFamily="Helvetica"
              fontSize="22px"
              lineHeight={1.2}
              color="#1C1C1E"
              cursor="pointer"
            >
              {data.title}
            </Box>
          </Typography>
          <Typography>
            <Box
              fontFamily="Helvetica"
              fontSize="16px"
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                '-webkit-line-clamp': 3,
                '-webkit-box-orient': 'vertical',
              }}
              color="rgba(28, 28, 30, 0.8)"
            >

              {data.description}
            </Box>
          </Typography>
          <Box style={{ display: "flex", marginTop: 10 }}>
            <img src="/Mic.png" style={{ height: 20 }} />
            <Typography>
              <Box
                fontFamily="Helvetica"
                fontWeight="500"
                fontSize="16px"
                lineHeight="20px"
                color="#000"
              >
                {data.userId.name}
              </Box>
            </Typography>
          </Box>
        </Grid>
      </a>
    );
  };


  const handleChangeSlider = () => { };

  function delay() {
    setTimeout(() => {
      setLoading(false)
    }, 100);
  }

  if (state.data === null && loading === false) {
    return <PageNotFound />
  }
  return (
    <div className={classes.root}>
      {loading ? (
        <Box
          height="100vh"
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img src={Logo} alt="logo" height="auto" width="170.81px" />
        </Box>
      ) : (
        <>
          <Container fixed>
            <Grid container justifyContent="center" spacing={2}>
              <Grid
                item
                style={{
                  padding: 20,
                  width: 270,
                  height: 460,
                  marginRight: 12,
                }}
                className="side-left-1"
              >
                <Typography>
                  <Box
                    textAlign="left"
                    pt={1}
                    fontFamily="Helvetica"
                    fontWeight="700"
                    lineHeight="35px"
                    fontSize="22px"
                    color="#1C1C1E"
                  >
                    Embark on a transformative journey of self-discovery and growth!
                  </Box>
                </Typography>

                <Typography>
                  <Box
                    textAlign="left"
                    pt={1}
                    mt={1}
                    fontFamily="Helvetica"
                    fontWeight="400"
                    lineHeight="20px"
                    fontSize="14px"
                    color="#828282"
                  >
                    Install Steev app now!
                  </Box>
                </Typography>

                <Box pt={2} display="block" mt={1} justifyContent="center">
                  <Link
                    href={
                      "https://play.google.com/store/apps/details?id=com.steev.live&hl=en_IN&gl=US"
                    }
                    target="_blank"
                  >
                    <img
                      className={classes.mediaImgfirst}
                      src={Google}
                      alt="Google"
                      height="auto"
                      width="200px"
                    />
                  </Link>
                  <Link
                    href={
                      "https://apps.apple.com/us/app/steev/id1561919092#?platform=iphone"
                    }
                    target="_blank"
                  >
                    <img
                      style={{ marginTop: 20 }}
                      className={classes.mediaImg}
                      src={AppStore}
                      alt="AppStore"
                      height="auto"
                      width="200px"
                    />
                  </Link>
                </Box>
              </Grid>
              <Grid item style={{ width: 500, marginRight: -10 }}>
                <Box className={classes.medialogo} py={5} textAlign="center">
                  <img src={Logo} alt="logo" height="auto" width="170.81px" />
                </Box>

                <Box className={classes.backgroundcolor}>
                  <Box className={classes.clockbg}>
                    <AccessTimeIcon className={classes.clockiconcolor} />
                    <span className={classes.datetext}>{mytimeZone()}</span>
                  </Box>
                  {state.data.tags.length && (
                    <Box mt={3}>{displayTags(state.data.tags, 100)}</Box>
                  )}
                  <Typography>
                    <Box
                      py={2}
                      mt={1}
                      fontWeight="700"
                      fontFamily="Helvetica"
                      lineHeight="30px"
                      fontSize="22px"
                      color="#1C1C1E"
                    >
                      {state.data.title}
                    </Box>
                  </Typography>
                  <Typography>
                    <Box
                      fontFamily="Helvetica"
                      lineHeight="22px"
                      fontSize="16px"
                      color="rgba(28, 28, 30, 0.8)"
                    >
                      {state.data.description}
                    </Box>
                  </Typography>

                  <Box py={2}>
                    <Grid container spacing={3}>
                      {state.hosts.map((value, i) => {
                        const spt = value.name.split(" ");
                        let avtarName = "";
                        spt.forEach((item) => {
                          if (
                            item !== "" &&
                            item !== null &&
                            item !== undefined &&
                            avtarName.length < 2
                          ) {
                            if (avtarName == "") {
                              avtarName = item.charAt(0);
                            } else {
                              avtarName += item.charAt(0);
                            }
                          }
                        });

                        return (
                          <Grid
                            item
                            key={value.name}
                            lg={3}
                            md={3}
                            sm={4}
                            xs={4}
                            className={classes.cursor}
                            onClick={() => history.push(`/${value.username}`)}
                          >
                            {value.profilePic !== undefined ? (
                              <Avatar
                                alt={value.name}
                                src={value.profilePic}
                                className={classes.large}
                              />
                            ) : (
                              <Avatar
                                alt={value.name}
                                style={{ background: "gray" }}
                                src={value.profilePic}
                                className={classes.large}
                              >
                                {" "}
                                {avtarName}{" "}
                              </Avatar>
                            )}

                            <Typography>
                              <Box
                                textAlign="center"
                                pt={1}
                                fontFamily="Helvetica"
                                fontWeight="700"
                                lineHeight="20px"
                                fontSize="10px"
                                color="#8A8FA9"
                              >
                                {value.type}
                              </Box>
                            </Typography>
                            <Typography>
                              <Box
                                className={classes.mediatext}
                                textAlign="center"
                                fontFamily="Helvetica"
                                fontWeight="400"
                                lineHeight="20px"
                                fontSize="14px"
                                color="#000000"
                              >
                                {value.name}
                              </Box>
                            </Typography>
                          </Grid>
                        );
                      })}
                    </Grid>

                    <Grid
                      display="flex"
                      style={{ marginTop: 20, display: "flex" }}
                    >
                      <Grid item md={6} style={{ width: 300 }}>
                        <Typography>
                          <Box
                            className={classes.mediatext}
                            textAlign="left"
                            fontFamily="Helvetica"
                            fontWeight="400"
                            lineHeight="20px"
                            fontSize="14px"
                            color="#8A8FA9"
                          >
                            {"Brought to you by"}
                          </Box>
                        </Typography>
                        <img
                          style={{ marginTop: 10, width: 150 }}
                          src={

                            "/kooberi.png"
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        md={4}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <img style={{ marginTop: 0 }} src={"/micOptions.png"} />
                        <Typography
                          style={{
                            marginLeft: 20,
                          }}
                        >
                          <Box
                            className={classes.mediatext}
                            textAlign="center"
                            fontFamily="Helvetica"
                            fontWeight="700"
                            lineHeight="20px"
                            fontSize="16px"
                            color="#000"
                          >
                            {viewers}
                          </Box>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

                <Box py={2} textAlign="center">
                  <Grid container>
                    <Grid item xs={4}>
                      <Box textAlign="right">
                        <IconButton
                          className={classes.borderbg}
                          aria-label="delete"
                          onClick={handleClickOpen}
                        >
                          <img
                            src={Share}
                            alt="Share"
                            height="auto"
                            width="20px"
                          />
                        </IconButton>
                        <Typography>
                          <Box
                            pt={1}
                            pr={1}
                            fontFamily="Helvetica"
                            fontWeight="400"
                            lineHeight="18px"
                            fontSize="12px"
                            color="#000000"
                          >
                            Share
                          </Box>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <IconButton
                        className={classes.borderbg}
                        aria-label="delete"
                        onClick={() =>
                          navigator.clipboard.writeText(window.location.href)
                        }
                      >
                        <img
                          src={Copy}
                          alt="Share"
                          height="auto"
                          width="20px"
                        />
                      </IconButton>
                      <Typography>
                        <Box
                          pt={1}
                          fontFamily="Helvetica"
                          fontWeight="400"
                          lineHeight="18px"
                          fontSize="12px"
                          color="#000000"
                        >
                          Copy Link
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Box pl={2} textAlign="left">
                        <IconButton
                          className={classes.borderbg}
                          aria-label="delete"
                          onClick={generateIcs}
                        >
                          <img
                            src={Calender}
                            alt="Share"
                            height="auto"
                            width="20px"
                          />
                        </IconButton>
                      </Box>
                      <Typography>
                        <Box
                          textAlign="left"
                          pt={1}
                          fontFamily="Helvetica"
                          fontWeight="400"
                          lineHeight="18px"
                          fontSize="12px"
                          color="#000000"
                        >
                          Add Calendar
                        </Box>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid
                item
                className="slide-right-1"
                style={{ width: 270, marginTop: 120, padding: 20 }}
              >
                {state.shows.length > 0 &&
                  state.shows.map((item) => {
                    return <>{displayShows(item)}</>;
                  })}
              </Grid>
            </Grid>
          </Container>
        </>
      )}
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
      <Box py={6} />
      {state.data &&

        <div>
          {url && (
            <div
              className="footer-stripe"
              style={{ height: isPlaying ? "100px" : "80px" }}
            >
              <div className="player-wrapper">
                <ReactPlayer
                  ref={playerRef}
                  progressInterval={500}
                  volume={volume}
                  playing={isPlaying}
                  muted={isMuted}
                  url={url}
                  className="player"
                  width="100%"
                  height="100%"
                  onEnded={() => {
                    console.log('Playing ended')
                  }}
                  onDuration={(d) => setDuration(d)}
                  onProgress={(o) => {
                    setProgressObj(o);
                    if (o.played == 1) {
                      updateViewers()
                      setProgressObj({
                        playedSeconds: 0,
                        loadedSeconds: 0,
                      })

                      setPlaying(true)
                      playerRef.current.seekTo(0, "seconds");
                    }
                  }}
                />

                <span className="group">
                  {/* <span
                className="group prev-audio"
                title="Previous track"
                onClick={() => {
                  if (index === 0) {
                    return;
                  }
                  setIndex(index - 1);
                }}
              >
                <PreviousIcon />
              </span> */}
                  <span
                    className={state.data.category == "offline" ? "group play-btn" : "group1 play-btn "}
                    title="Click to play"
                    onClick={() => {
                      if (!isPlaying) {
                        updateViewers()
                      }
                      setPlaying(!isPlaying)
                    }}
                  >
                    {isPlaying ? (
                      <PauseCircleOutlineIcon
                        style={{ height: "2em", width: "150px" }}
                      />
                    ) : (
                      <PlayCircleOutlineIcon
                        style={{ height: "2em", width: "150px" }}
                      />
                    )}
                  </span>
                  {/* <span
                className="group next-audio"
                title="Next track"
                onClick={() => {
                  if (index === files.length - 1) {
                    return;
                  }
                  setIndex(index + 1);
                }}
              >
                <NextIcon />
              </span> */}
                </span>
              </div>
              {isPlaying && (
                <div className={state.data.category == "offline" ? "slider" : "slider mt-slider"} >
                  <Slider
                    max={Math.ceil(progessObj.loadedSeconds)}
                    defaultValue={0}
                    value={Math.ceil(progessObj.playedSeconds)}
                    onChange={(v) => {
                      // setProgressObj({
                      //   playedSeconds:v
                      // });

                      playerRef.current.seekTo(parseFloat(v), "seconds");
                    }}
                  />
                  {/* <Slider
                value={isMuted ? 0 : volume}
                className="sound-operation"
                onChange={(v) => {
                  setVolume(v);
                  if (v === 0 && !isMuted) {
                    setMuted(true);
                  } else if (v > 0 && isMuted) {
                    setMuted(false);
                  }
                }}
                {...{ min: 0, max: 1, step: 0.01 }}
              /> */}

                  <span className="duration">
                    {isPlaying ? (
                      <FormattedTime
                        numSeconds={
                          Math.ceil(duration) -
                          Math.ceil(progessObj.playedSeconds)
                        }
                      />
                    ) : (
                      <FormattedTime numSeconds={duration} />
                    )}
                  </span>

                  {/* <span className="duration">
                {isPlaying ?(
                          <FormattedTime numSeconds={Math.ceil(progessObj.loadedSeconds)-Math.ceil(progessObj.playedSeconds)} />
                ):(
                  <FormattedTime numSeconds={duration} />
                )
               
                }

              </span> */}
                </div>
              )}
            </div>
          )}
        </div>
      }

    </div>
  );
}
